import { useGlobalData } from '@/context/global';
import { getFetcher } from '@/services/global';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import store from 'store';
import { useRouter } from 'next/router';
import { decodeJwt } from '@/utils/global';
import { getCookie } from 'cookies-next';

export async function useCategoryTree(
  // isChangeLang, setIsChangeLang
) {
  const { locale } = useRouter();
  const { dispatch } = useGlobalData();
  const handshake = getCookie('handshake');
  const [fetchTree, setFetchTree] = useState(false)

  const decodedToken = decodeJwt(handshake);
  const { mutate } = useSWR(
    () =>
      fetchTree ? `/api/category/tree?locale=${locale}` : null,
    getFetcher,
    {
      revalidateOnMount: true, // 30 minutes in milliseconds
      revalidateOnFocus: false,
      onSuccess: (data) => {
        dispatch({
          type: 'GET_CAT_TREE',
          payload: {
            ...(data?.data?.data || {}),
            cacheTime: Date.now() + 30 * 60 * 1000,
            locale,
          },
        });
        setFetchTree(false)
      },
    }
  );
  
  useEffect(() => {
    const isThereTree = store.get('app_data')?.categoryData?.cacheTime > Date.now() && locale === decodedToken?.storeName && locale === store.get('app_data')?.categoryData?.locale
    // isThereTree && setIsChangeLang(false);

    if (locale !== decodedToken?.storeName) {
      console.log('11111');
      setFetchTree(true)
      setTimeout(() => mutate(), 10)
      // setIsChangeLang(false);
    } else if (
      isThereTree
    ) {
      console.log('22222');
      dispatch({
        type: 'GET_CAT_TREE',
        payload: {
          ...store.get('app_data')?.categoryData,
          cacheTime: store.get('app_data')?.cacheTime,
        },
      });
    } else {
      console.log('33333');
      setFetchTree(true)
      setTimeout(() => mutate(), 10)
      // setIsChangeLang(false);
    }

  }, [
    decodedToken?.storeName,
    dispatch,
    // isChangeLang,
    locale,
    mutate,
    // setIsChangeLang,
  ]);

  return null;
}
